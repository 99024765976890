import authTypes from '@/types/auth';
import precomproTypes from '@/types/precompro';
import { mapGetters } from 'vuex';
export function getAuthMixin () {
  return {
    mounted () {
      this.$store.commit(authTypes.mutations.setUser);
      this.$store.commit(authTypes.mutations.setPayment);
    },
    computed: {
      ...mapGetters({
        getUser: [authTypes.getters.user],
        getPayment: [authTypes.getters.payment],
        getLogged: [authTypes.getters.logged]
      }),
      payment () {
        return this.getPayment;
      },
      user () {
        return this.getUser;
      },
      isLogged () {
        return this.getLogged;
      }
    }
  };
}

export function getVendorMixin () {
  return {
    mounted () {
      this.$store.commit(precomproTypes.mutations.setVendor);
    },
    computed: {
      ...mapGetters({
        getVendor: [precomproTypes.getters.vendor]
      }),
      vendor () {
        return this.getVendor;
      }
    }
  };
}
