<template>
  <div>
    <link rel="stylesheet" type="text/css" href="assets/css/bootstrap.min.css">
    <link rel="stylesheet" type="text/css" href="assets/css/mdb.min.css">
    <link rel="stylesheet" type="text/css" href="assets/css/estilos.css">
    <div class="container-fluid bk-header dekstop">
      <div class="container-fluid">
        <div class="row">
          <div class="col col-md-6" style="text-align: center; cursor: pointer;" @click="$router.push('/')">
            <img src="/images/logotipo.png" alt="" class="img-fluid">
          </div>
          <div class="col col-md-5">
            <div class="row">
              <!--<div class="col-md-5">
                <a href="javascript:void(0)" @click="$router.push('/')" class="btn btn2">
                  <span class="boton-1">Volver a precompro.com</span>
                </a>
              </div>-->
              <div class="col-md-5">
                <button type="button" class="btn btn3" @click="openWarningModal">
                  <span class="contactanos">Cotiza aquí</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
        <div class="row">
          <div class="col">
          </div>
          <div class="col-md-12 col- text-center">
            <br>
            <h2 class="garanti color_text">Garantiza tu <strong>tiempo.</strong></h2><br>
          </div>
        </div>
      </div>
    </div>
    <hr class="line_blue dekstop">
    <div class="container-fluid bk-header mobile">
      <div class="container">
        <div class="row">
          <div class="col col-md-6" @click="$router.push('/')">
            <img src="/images/logotipo.png" alt="" class="img-fluid im">
          </div>
          <div class="col col-md-6">
            <a href="javascript:void(0)" @click="$router.push('/')" class="btn btn2 mx-auto d-block">
              <span class="boton-1">Volver a precompro.com</span>
            </a>
          </div>
        </div>
        <br>
        <br>
        <div class="row">
          <div class="col">
          </div>
          <div class="col-md-12 col- text-center">
            <h5 class="color_text garanti">Garantiza tu tiempo.</h5><br>
            <p class="text-center white-h">
              Explora ahora los mejores restaurantes del país.
            </p>
          </div>
          <div class="col d-flex justify-content-center text-center">
            <button type="button" class="btn  btn3" @click="openWarningModal">
              <span class="transform">Cotiza aquí</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr class="line_blue">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mt-5">
          <h3 class="d-flex justify-content-center text-center text-1">Mejora la gestión de tu restaurante</h3>
        </div>
      </div>
    </div>
    <hr>
    <div class="container mt-5">
      <div class="row d-flex justify-content-center text-center">
        <div class="col-md-4 service serv_mar">
          <h5 class="">Facilita y mejora</h5>
          <h6>la experiencia de tus clientes</h6>
          <p>Somos una herramienta que facilita y optimiza la gestión de las reservas, mejorando la experiencia del comensal de principio a fin.</p>
        </div>
        <div class="col-md-4 service serv_mar">
          <h5>Maximiza</h5>
          <h6>La operación</h6>
          <p>Buscamos maximizar la operación y capacidad de los establecimientos, disminuyéndoles el trabajo administrativo y eliminando tareas adicionales. </p>
        </div>
        <div class="col-md-4 service serv_mar">
          <h5>No cobramos por</h5>
          <h6> tus clientes.</h6>
          <p>Cuando realizan la búsqueda de tu restaurante, el resultado principal va a ser el de tu página y no la de terceros. </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-4 mb-4">
        <div class="col-md-12 service">
          <hr class="hr-blue">
          <p class="d-flex justify-content-center text-center ">Incrementa y optimiza tus canales de reservas. Ten control sobre toda tú operación.</p>
        </div>
      </div>
    </div>
    <div class="container-fluid bk-black">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mt-4 service">
            <h3 class="d-flex justify-content-center text-center color_text mt-5">¿Como se generan las reservas?
            </h3>
            <hr class="hr-blue">
            <p class="text-center color_text">Tus clientes hacen la reserva directamente desde tus canales web redes, Google, etc. <br></p>
            <!--<strong> ¡EL TRÁFICO ES TUYO! </strong> </p>-->
          </div>
        </div>
        <div class="row d-flex justify-content-center text-center cajas">
          <div class="col-md-4 cajas">
            <img src="/images/select-1.png" alt="" class="img-fluid">
          </div>
          <div class="col-md-4 cajas">
            <img src="/images/select-2.png" alt="" class="img-fluid">
          </div>
          <div class="col-md-4 cajas">
            <img src="/images/select-3.png" alt="" class="img-fluid">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center mt-4">
            <h3 class="d-flex justify-content-center text-center color_text transform_mayus">¡Personalizable!
            </h3>
            <p class="text-center color_text box-text mb-5"> Conserva e impulsa tu imagen</p>
            <!--<img src="/images/arrow.png" alt="" class="img-fluid"><br><br>-->
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-12 text-center mt-4">
        <p class="text-center box-text color-text"> Conoce los canales por los que llegan tus clientes</p>
        <img src="/images/arrow_blue.png" alt="" class="img-fluid"><br><br>
      </div>
    </div>
    <div class="container boxes">
      <div class="row abs-center">
        <div class="col-md-6">
          <img src="images/service-1.png" alt="" class="img-fluid">
        </div>
        <div class="col-md-6">
          <div class="vl"></div>
          <p class="tittles lefts">Dentro de tu <strong>pagina web</strong> </p>
          <p class="box lefts">
            Incluimos dentro de tu página web un proceso fácil y amigable para que tus clientes puedan reservar, en tiempo real y con confirmación inmediata a través de email o SMS.
            <strong>SIEMPRE CON LA IMAGEN DE TU RESTAURANTE</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="container boxes dekstop">
      <div class="row abs-center">
        <div class="col-md-6 rights">
          <p class="tittles rights">Toma de reservas<strong> telefónicas</strong> </p>
          <p class="box rights">
            Olvídate del papel y lápiz. <strong>Tecnifícate ahora.</strong> <br><br>
            Ingresa tus reservas telefónicas dentro del mismo sistema y ten conocimiento completo sobre tu disponibilidad. Incluye requerimientos de tus clientes y ten <br>
            <strong>control total sobre tus reservas en un mismo lugar. </strong>
          </p>
        </div>
        <div class="col-md-6">
          <img src="/images/service-2.png" alt="" class="img-fluid">
          <div class="vlr"></div>
        </div>
      </div>
    </div>
    <div class="container-fluid boxes mobile">
      <div class="row abs-center">
        <div class="col-md-6 col-mobile">
          <img src="/images/service-2-mobile.png" alt="" class="img-fluid">
        </div>
        <div class="col-md-6 rights">
          <p class="tittles rights">Toma de reservas<strong> telefónicas</strong> </p>
          <p class="box rights">
            Olvídate del papel y lápiz. <strong>Tecnifícate ahora.</strong> <br><br>
            Ingresa tus reservas telefónicas dentro del mismo sistema y ten conocimiento completo sobre tu disponibilidad. Incluye requerimientos de tus clientes y ten <br>
            <strong>control total sobre tus reservas en un mismo lugar. </strong>
          </p>
          <hr class="hr-blue">
        </div>
      </div>
    </div>
    <div class="container-fluid bk-logo boxes dekstop">
      <div class="container">
        <div class="row abs-center">
          <div class="col-md-6">
            <img src="/images/47028.png" alt="" class="img-fluid">
          </div>
          <div class="col-md-6">
            <div class="vl"></div>
            <p class="tittles lefts"><strong>WALK-IN </strong>Mide los clientes <strong> sin reservas</strong> </p>
            <p class="box lefts">
              Las personas que llegan caminando al restaurante sin reserva, también las puedes medir. Inclúyelas dentro de tu rotación y así tienes <strong> control total de tus mesas desde cualquier lugar. </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid boxes mobile">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <img src="/images/service-3-mobile.png" alt="" class="img-fluid">
          </div>
          <div class="col-md-6">
            <p class="tittles"><strong>WALK-IN </strong>Mide los clientes <strong> sin reservas</strong> </p>
            <p class="box">
              Las personas que llegan caminando al restaurante sin reserva, también las puedes medir. Inclúyelas dentro de tu rotación y así tienes <strong> control total de tus mesas desde cualquier lugar. </strong>
            </p>
            <hr class="hr-blue">
          </div>
        </div>
      </div>
    </div>
    <div class="container boxes dekstop">
      <div class="row abs-center">
        <div class="col-md-6 rights">
          <p class="tittles rights">Centralización reservas de <strong> redes sociales</strong> </p>
          <p class="box rights">
              Los clientes podrán entrar a las redes sociales del establecimiento y hacer reservas con <strong> confirmación inmediata,</strong> evitando trabajo operativo.
          </p>
        </div>
        <div class="col-md-6">
          <img src="/images/service-3.png" alt="" class="img-fluid">
          <div class="vlr"></div>
        </div>
      </div>
    </div>
    <div class="container boxes mobile">
      <div class="row abs-center">
        <div class="col-md-6 col-mobile">
          <img src="/images/servici-4-mobile.png" alt="" class="img-fluid">
          <div class="vlr"></div>
        </div>
        <div class="col-md-6 rights">
          <p class="tittles rights">Centralización reservas de <strong> redes sociales</strong> </p>
          <p class="box rights">
            Los clientes podrán entrar a las redes sociales del establecimiento y hacer reservas con <strong> confirmación inmediata,</strong> evitando trabajo operativo.
          </p>
          <hr class="hr-blue">
        </div>
      </div>
    </div>
    <div class="container-fluid boxes">
      <div class="container">
        <div class="row abs-center">
          <div class="col-md-6">
            <img src="/images/service-4.png" alt="" class="img-fluid">
          </div>
          <div class="col-md-6">
            <div class="vl"></div>
            <p class="tittles lefts"><strong>Reservas desde Google</strong></p>
            <p class="box lefts">
              Cuando tus clientes te busquen en <strong>Google</strong> las personas podrán reservar con <strong>disponibilidad real y confirmación automática.</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container boxes mobile">
      <div class="row abs-center">
        <div class="col-md-6 col-mobile">
          <img src="/images/service-5.png" alt="" class="img-fluid">
          <div class="vlr"></div>
        </div>
        <div class="col-md-6 rights">
          <p class="tittles rights">Gift card, experiencias y evento.</p>
          <hr class="hr-blue">
          <p class="box rights">
            Genera ventas adicionales de experiencias, eventos y bonos de regalo personalizados.
          </p>
        </div>
      </div>
    </div>
    <div class="container boxes dekstop">
      <div class="row abs-center">
        <div class="col-md-6 rights">
          <p class="tittles rights">Gift card, experiencias y evento.</p>
          <p class="box rights">
            Genera ventas adicionales de experiencias, eventos y bonos de regalo personalizados.
          </p>
        </div>
        <div class="col-md-6">
          <img src="/images/service-5.png" alt="" class="img-fluid">
          <div class="vlr"></div>
        </div>
      </div>
    </div>
    <div class="container-fluid bk-black boxes2">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mt-4 mb-4">
            <p class="text-center color_text light"> Garantiza el valor de tu <strong>tiempo.</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="contain poten">
      <div class="row">
        <div class="col-md-4">
        </div>
        <div class="col-md-4 text-center">
          <p class="tittles"><strong>Potencializa</strong> tus ventas</p>
          <hr class="hr-blue">
          <p class="box text-center mb-5 mt-5">
            Si quieres dejar de perder tiempo y dinero en las mesas reservadas que nunca llegan, te tenemos la solución.
            <strong> Las precompras</strong> son una reserva hecha con un anticipo, con el fin de garantizar la asistencia y un consumo mínimo en caso de ser requerido.
            <br><br>
            Adicional, en caso que tengas mas de un restaurante o varias sedes del mismo, puedes mantener a tus clientes dentro de estos. En el momento que en alguno no tienes disponibilidad, al cliente le da la opción de ver los otros, generando una <strong>venta cruzada.</strong>
          </p>
        </div>
        <div class="col-md-4">
        </div>
      </div>
    </div>
    <div class="container-fluid bk-2 dekstop">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-">
            <h3 class="garan">Garantiza</h3>
            <h5>los tiempos de tu restaurante.</h5><br>
            <hr class="hr-blue2">
            <br>
            <p class="box">
              Tu tiempo vale, optimízalo.
            </p>
            <button type="button" class="btn" @click="contact = true">
              <strong class="box transform">Contacta ahora</strong>
            </button>
          </div>
          <div class="col">
          </div>
        </div>
      </div>
    </div>
    <div class="container margins">
      <div class="row">
        <div class="col">
        </div>
        <div class="col-md-8 col-sm-12 text-center">
          <p class="tittles ahorro"><strong>Ahorra dinero</strong></p>
          <hr class="hr-blue">
          <p class="box text-center">
            Disminuye tus no-shows a través de reservas pre compradas.
          </p>
        </div>
        <div class="col">
        </div>
      </div>
    </div>
    <div class="text-center dekstop porcentaje">
      <img src="/images/cel.png" alt="" class="img-fluid">
    </div>
    <div class="text-center mobile">
      <img src="/images/cel-mobile.png" alt="" class="img-fluid">
    </div>
    <div class="container-fluid bk-3 dekstop">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <img src="images/icon-house.png" alt="" style="float: left;">
            <h5 class="caja-left">¿Estas Interesado?</h5>
            <p class="caja-left2">
              Si estas interesado y quieres más información déjanos tus datos y pronto estaremos en contacto.
            </p>
          </div>
          <div class="col-md-3">
            <button type="button" class="btn" @click="contact = true">
              <strong class="box transform">Contacta ahora</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bk-3 mobile">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <br><br>
            <h5 class="text-center">¿Estas Interesado?</h5>
            <p class="text-center">
              Si estas interesado y quieres más información déjanos tus datos y pronto estaremos en contacto.
            </p>
          </div>
          <div class="col-md-12 text-center">
            <button type="button" class="btn btn-mob" @click="contact = true">
              <strong class="box contactanos">Contacta ahora</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Contacto -->
    <div id="contact-modal" class="modal" style="display: block; overflow: auto;" v-if="contact" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header text-center">
            <div class="row">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="col-md-12 text-center mt-4">
              <h4 class="modal-title w-100 font-weight-bold">Contáctanos</h4>
              <hr class="hr-blue">
              <p class="contact-text">
                Déjanos tus datos para ponernos en contacto contigo y contarte de todas las funciones de nuestra herramienta.
              </p>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-7">
                <div class="modal-body mx-3">
                  <div class="md-form mb-1">
                    <input type="text" id="defaultForm-name" name="names" class="form-control" v-model="name" v-validate="'required|alpha_spaces'">
                    <label for="defaultForm-name">Nombre</label>
                    <span v-if="errors.has('names')" style="color: red; font-size: 10px;">* {{ errors.first('names') }}</span>
                  </div>
                  <div class="md-form mb-1">
                    <input type="tel" id="defaultForm-phone" name="phone" class="form-control" v-model="phone" v-validate="'required|numeric'">
                    <label for="defaultForm-phone">Teléfono</label>
                    <span v-if="errors.has('phone')" style="color: red; font-size: 10px;">* {{ errors.first('phone') }}</span>
                  </div>
                  <div class="md-form mb-1">
                    <input type="email" id="defaultForm-email" name="email" class="form-control" v-model="email" v-validate="'required|email'">
                    <label :data-error="errors.first('email')" for="defaultForm-email">Email</label>
                    <span v-if="errors.has('email')" style="color: red; font-size: 10px;">* {{ errors.first('email') }}</span>
                  </div>
                  <div class="md-form mb-1">
                    <input type="text" id="defaultForm-restaurant" name="restaurant" class="form-control" v-model="restaurant" v-validate="'required'">
                    <label for="defaultForm-restaurant">Nombre restaurante</label>
                    <span v-if="errors.has('restaurant')" style="color: red; font-size: 10px;">* {{ errors.first('restaurant') }}</span>
                  </div>
                  <div class="md-form mb-1">
                    <input type="text" id="defaultForm-city" name="citys" class="form-control" v-model="city" v-validate="'required|alpha_spaces'">
                    <label for="defaultForm-city">Ciudad</label>
                    <span v-if="errors.has('citys')" style="color: red; font-size: 10px;">* {{ errors.first('citys') }}</span>
                  </div>
                  <div class="md-form">
                    <textarea id="textarea-char-counter" class="form-control md-textarea" length="100" rows="2" v-model="comments"></textarea>
                    <label for="textarea-char-counter">Comentarios</label>
                  </div>
                  <div class="d-flex justify-content-right">
                    <button type="button" class="btn-send" @click="send" v-if="!sending">
                      <strong class="box">Enviar</strong>
                    </button>
                    <button type="button" class="btn-send" @click="send" v-if="sending">
                      <strong class="box"><i class="fa fa-spinner fa-spin"></i>&nbsp;Enviando ...</strong>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <ul class="list-group list-contact">
                  <li class="list-group-item   text-center box-text color-text lista">
                    <i class="fas fa-envelope margin_list"></i>
                      mariana@precompro.com
                  </li>
                  <li class="list-group-item   text-center box-text color-text lista">
                    <i class="fas fa-phone-alt margin_list"></i>
                      +57 (313) 219 5332
                  </li>
                  <li class="list-group-item   text-center box-text color-text lista">
                    <i class="fas fa-map-marker-alt margin_list"></i>
                      Bogotá - Colombia
                  </li>
                </ul>
                <div style="padding-left: 10px;">
                  <div class="alert alert-danger" v-if="error">
                    <span>Ocurrió un error al enviar la petición.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Advertencia -->
    <div id="warning-modal" class="modal" style="display: block; overflow: auto;" v-if="warning" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body mb-md-2">
            <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close" @click="close">
                <span aria-hidden="true">&times;</span>
              </button>
            <div class="row">
              <div class="col-md-12 text-center mt-md-4">
                <div class="d-flex justify-content-center w-100">
                  <img src="/icons/icono-alerta-01.svg" class="warning-icon" alt="Advertencia">
                </div>
                <h4 class="modal-title w-100 font-weight-bold mb-3 mt-1">¡Recuerda!</h4>
              </div>
              <div class="col-md-12">
                <p class="warning-text">
                  Por este canal <strong>no se pueden hacer reservas, </strong>este canal es exclusivo para propietarios o administradores de restaurantes
                  que quieran conocer más sobre nuestra herramienta.
                </p>
              </div>
            </div>
            <div class="row justify-content-center mb-2 mb-md-4">
              <div class="col-sm-12 col-md-5 mb-md-0 mb-2">
                <button class="btn3 w-100 rounded fs-5" @click="openContactModal">¡Entiendo, seguir con el registro!</button>
              </div>
              <div class="col-sm-12 col-md-5">
                <button class="btn3 w-100 rounded fs-5" @click="close">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Felicitaciones -->
    <div id="congrats-modal" class="modal" style="display: block; overflow: auto;" v-if="congratulations" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-center mt-4">
                <h4 class="modal-title w-100 font-weight-bold text-uppercase">¡Felicitaciones!</h4>
                <hr class="hr-blue">
              </div>
              <div class="col-md-12 mt-2">
                <p class="text-center box-text color-text">
                  Tu registro fue enviado correctamente, <br> muy pronto nos pondremos en contacto contigo.
                </p>
              </div>
              <div class="d-flex justify-content-center w-100">
                <button type="button" class="btn-send" @click="close">
                  <strong class="box">Cerrar</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="container-fluid bk-black">
      <div class="container">
        <div class="row">
          <div class="col-md-4 foot-line">
            <a href="javascript:void(0)" @click="$router.push('/privacyPolicy')">Politicas de privacidad</a><br>
            <a href="javascript:void(0)" @click="$router.push('/termsAndConditions')">Términos y condiciones</a>
            <hr>
          </div>
          <div class="col-md-4 foot-line">
            <p class="footer-text">
              <strong> Pre compro</strong> en tu restaurante?<br>
              Contactanos <br>
              Síguenos en:
              <a href="javascript:void(0)" @click="redes('https://twitter.com/precomprocol')"><i class="fa fa-twitter" aria-hidden="true"></i></a>&nbsp;&nbsp;
              <a href="javascript:void(0)" @click="redes('https://instagram.com/precomprocol')"><i class="fa fa-instagram" aria-hidden="true"></i></a>&nbsp;&nbsp;
              <a href="javascript:void(0)" @click="redes('https://facebook.com/precompro')"><i class="fa fa-facebook" aria-hidden="true"></i></a>
            </p>
            <hr>
          </div>
          <div class="col-md-3 text-center">
            &nbsp;&nbsp;
            <img src="/images/precompro.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="row dekstop">
        <div class="col text-center"><br><br>
          <p class="text-footer">Copyright © {{ new Date().getFullYear() }} PreCompro.com, Bogotá Colombia - All rights reserved.</p>
        </div>
      </div>
      <div class="row mobile">
        <div class="col text-center"><br><br>
          <p class="text-footer">Copyright © {{ new Date().getFullYear() }} PreCompro.com, Bogotá Colombia - All rights reserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { getAuthMixin } from '@/mixins/auth';
import authTypes from '@/types/auth';
import precomproTypes from '@/types/precompro';
import { Base64 } from 'js-base64';
export default {
  mixins: [getAuthMixin()],
  data () {
    return {
      name: null,
      phone: null,
      restaurant: null,
      comments: null,
      email: null,
      city: null,
      error: false,
      sending: false,
      contact: false,
      warning: false,
      congratulations: false
    };
  },
  mounted () {
    this.$store.commit(authTypes.mutations.setUserId);
    this.getMobile();
    window.addEventListener('load', () => {
      navigator.onLine ? this.showStatus(true) : this.showStatus(false);
      window.addEventListener('online', () => {
        this.showStatus(true);
      });
      window.addEventListener('offline', () => {
        this.showStatus(false);
      });
    });
    this.$store.commit(precomproTypes.mutations.setReservation);
    if (this.reservation.vendorAvailability) {
      this.$store.dispatch({ type: 'precompro:setDeleteReservation', data: { vendorId: this.reservation.vendorId, availabilityId: this.reservation.vendorAvailability.id } });
    }
    this.$store.commit(precomproTypes.mutations.setReservationRecommended, []);
    this.$store.commit(precomproTypes.mutations.setReservationVendors, []);
    this.$store.commit(precomproTypes.mutations.setReservationAvailability, []);
    this.$store.commit(precomproTypes.mutations.setReservation, []);
    this.$store.commit(precomproTypes.mutations.setVendor, []);
    this.$store.commit(precomproTypes.mutations.setPaymentType, '');
    this.$store.commit(precomproTypes.mutations.setReservationAvailabilityVendor, []);
    this.$store.commit(precomproTypes.mutations.setSource, '');
    this.$store.commit(precomproTypes.mutations.setReservationPeople, 2);
    this.$store.commit(precomproTypes.mutations.setReservationDate, null);
    this.$store.commit(precomproTypes.mutations.setReservationHours, null);
    this.$store.commit(precomproTypes.mutations.setReservationShowSearchButton, false);
    this.chargueScript('assets/js/mdb.min.js', true);
    this.chargueScript('assets/js/popper.min.js', true);
    window.addEventListener('message', this.sociaLogin);
  },
  methods: {
    /**
       * [sociaLogin description]
       * @param  {[type]} event [description]
       * @return {[type]}       [description]
       */
    sociaLogin (event) {
      if (event.data.userId !== undefined) {
        // Almaceno en el storage la data del usuario
        window.localStorage.setItem('precompro_token', event.data.token);
        window.localStorage.setItem('precompro_user', Base64.encode(JSON.stringify(event.data.user)));
        window.localStorage.setItem('precompro_payment', Base64.encode(JSON.stringify([])));
        window.localStorage.setItem('precompro_userId', event.data.userId);
        this.$store.commit(authTypes.mutations.setUser);
        this.$store.commit(authTypes.mutations.setUserId);
        this.$store.commit(authTypes.mutations.setShowAuth, false);
      }
    },
    docs (url) {
      window.open(url, '_blank');
    },
    redes (url) {
      window.open(url, '_blank');
    },
    send () {
      const data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        city: this.city,
        restaurant: this.restaurant,
        comments: this.comments
      };
      this.$validator.validateAll().then(result => {
        if (result) {
          this.sending = true;
          this.$store.dispatch({ type: 'precompro:contact', data }).then(response => {
            this.$validator.reset();
            this.sending = false;
            this.error = false;
            this.contact = false;
            this.openCongratsModal();
          }).catch(() => {
            this.sending = false;
            this.error = true;
          });
        }
      });
    },
    close () {
      this.name = null;
      this.email = null;
      this.city = null;
      this.restaurant = null;
      this.comments = null;
      this.phone = null;
      this.contact = false;
      this.error = false;
      this.congratulations = false;
      this.contact = false;
      this.warning = false;
      document.body.classList.remove('no-scroll');
    },
    openContactModal () {
      this.warning = false;
      this.contact = true;
      document.body.classList.add('no-scroll');
    },
    openWarningModal () {
      this.warning = true;
      document.body.classList.add('no-scroll');
    },
    openCongratsModal () {
      this.congratulations = true;
      document.body.classList.add('no-scroll');
    }
  },
  destroyed () {
    // Se destruye el evento, para que no quede escuchando y se dupliquen las peticiones
    window.removeEventListener('message', this.sociaLogin);
  }
};
</script>

<style lang="scss" scoped>

#contact-modal{
  .contact-text{
    text-align: center;
    color: #151E26;
    font-size: 20px;
    margin: 0 auto;
  }
}

#warning-modal{
  .modal-body{
    margin-bottom: 0;
    .warning-text{
      text-align: center;
      color: #151E26;
      font-size: 18px;
    }
  }
}

@media (min-width: 768px){
  #contact-modal{
    .modal-dialog{
      max-width: 740px;
      .contact-text{
        width: 75%;
      }
    }
  }
  #warning-modal{
    .modal-dialog{
      max-width: 650px;

      .warning-text{
        padding: 0 2.3rem;
      }
    }
  }
}

@media (min-width: 992px){
  #contact-modal{
    .modal-dialog{
      max-width: 800px;
    }
  }
  #congrats-modal{
    .modal-dialog{
      max-width: 780px;
    }
  }
}

@media(max-width: 600px){
  .modal-dialog{
    .hr-blue{
      width: 10%;
    }
  }
}

.warning-icon {
  width: 100px;
  height: 100px;
}

.modal{
  background-color: rgba(0, 0, 0, 0.5);

  button.close{
    z-index: 9;
  }
}
</style>
