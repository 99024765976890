<template>
  <div id="Maintenance">
    <div class="infoImg">
      <div class="infoImg-cont">
        <img class="img-icon" src="/images/precompro_logo_banner.png" />
        <span class="text-up">¡Hola!</span>
        <span class="text-down">
          ¿Quieres dejar de recibir correos de notificaciones? Para dejar de
          recibir correos únicamente debes escribir un correo a
          contacto@precompro.com con el asunto: “Desuscribir correo” desde el
          correo que quieres dejar de recibir los correos. Y en el cuerpo del
          correo debe ir tu nombre y teléfono para poder sacarlos también de la
          base de datos. Debes tener en cuenta la próxima vez que hagas una
          reserva en algún restaurante aliado o que use el sistema de reservas,
          que tus datos ingresaran nuevamente a la base de datos y en caso de
          que quieras volver a eliminarlos deberás repetir el proceso. Adicional
          tus datos serán borrados de la base de Precompro, pero el restaurante
          también tiene acceso y uso de estos datos, en ese caso deberás
          comunicarte directamente con el restaurante para que te den de baja de
          su base de datos..</span
        >
        -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Instructions'
};
</script>
<style lang="scss">
 @font-face {
    font-family: confortaaBold;
    src: url(/fuentes/confortaa/Comfortaa-Bold.ttf);
  }
#Maintenance {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  background-image: url(../../public/assets/images/maintenance.jpg);
  padding: 80px;
  .infoImg {
    display: flex;
    background-color: #121b22;
    opacity: 0.8;
    width: 620px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    border-radius: 15px;
    &-cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img-icon {
        width: 60%;
      }
      .text-up {
        padding: 10px;
        font-size: 30px;
        font-family: confortaaBold;
        color: #fff;
      }
      .text-down {
        color: #fff;
        font-size: 22px;
        font-weight: 500;
        text-align: center;
        border-bottom: solid 1px #e6e6e6;
        padding: 10px;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
