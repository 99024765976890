<template>
  <div style="height: 100%;">
    <Toolbar></Toolbar>
    <div class="row">
      <div class="col-12">
        <iframe src="https://api.precompro.com/docs/terminosCondiciones.pdf" width="100%" height="880"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import Toolbar from '@/components/Banner/ToolbarInformativo';
export default {
  components: { Toolbar }
};
</script>
