<template>
  <div class="parentGoogleSub">
    <div class="unsubscribe-google">
      <div class="left">
          <section class="unsubscribe-head">
              <span class="unsubscribe-head-title">DARSE DE BAJA DEL BOTÓN DE RESERVA CON GOOGLE </span>
              <span class="unsubscribe-head-subtitle">Enviando este formulario solicitas desactivar el botón de reservar con Google, ten presente que tus clientes ya no podrán reservar por este canal.</span>
          </section>
          <section class="form-date">
              {{ getDay() }}
          </section>
          <section class="unsubscribe-form">
              <div class="unsubscribe-form-item">
                  <label for="exampleFormControlInput1" class="form-label">Nombre del restaurante</label>
                  <input type="text" class="form-control" @change="checkValid()" minlength="3" v-model="info.name" :class="{borderError: valid.name === 0}" :placeholder="(valid.name === 0) ? 'Debes ingresar al menos 3 caracteres' : ''">
              </div>
              <div class="unsubscribe-form-item">
                  <label for="exampleFormControlInput1" class="form-label">Motivo por el cual deseas desactivar el botón</label>
                  <input type="text" class="form-control" minlength="10" v-model="info.reason" :class="{borderError: valid.reason === 0}" :placeholder="(valid.reason === 0) ? 'Debes ingresar al menos 10 caracteres' : ''">
              </div>
              <div class="unsubscribe-form-item">
                  <label for="exampleFormControlInput1" class="form-label">Nombre del solicitante</label>
                  <input type="text" class="form-control" minlength="3" v-model="info.requestName" :class="{borderError: valid.requestName === 0}" :placeholder="(valid.requestName === 0) ? 'Debes ingresar al menos 3 caracteres' : ''">
              </div>
              <div class="unsubscribe-form-item">
                  <label for="exampleFormControlInput1" class="form-label">Cargo del solicitante</label>
                  <input type="text" class="form-control" minlength="6" v-model="info.requestCharge" :class="{borderError: valid.requestCharge === 0}" :placeholder="(valid.requestCharge === 0) ? 'Debes ingresar al menos 6 caracteres' : ''">
              </div>
          </section>
          <section class="unsubscribe-footer">
              <span class="unsubscribe-text3">Recuerda que esta solicitud solo será efectiva siempre y cuando seas la persona encargada del restaurante o hayas solicitado este servicio</span>
          </section>
          <section class="unsubscribe-actions">
              <button class="unsubscribe-actions-send" v-if="!isLoading" :class="{disabled: !isValid, enabled: isValid}" @click="sendForm()">
                Enviar</button>
              <button class="unsubscribe-actions-send enabled" v-else>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;&nbsp;Enviando...
              </button>
              <button class="unsubscribe-actions-close" @click="sendClose()">Cerrar</button>
          </section>
      </div>
      <div class="rigth">
          <section class="unsubscribe-image"></section>
      </div>
    </div>
  </div>
</template>
<script>
import serviceMail from '@/helpers/serviceMail';

export default {
  data () {
    return {
      isValid: false,
      isLoading: false,
      valid: {
        name: 0,
        reason: 0,
        requestName: 0,
        requestCharge: 0
      },
      info: {
        name: '',
        reason: '',
        requestName: '',
        requestCharge: ''
      }
    };
  },
  methods: {
    getDay () {
      return this.$moment().format('MMMM DD[,] YYYY');
    },
    checkValid () {
      if (this.info.name != null && this.info.name.length >= 3) {
        this.valid.name = 1;
      } else {
        this.valid.name = 0;
      }
      if (this.info.reason != null && this.info.reason.length >= 10) {
        this.valid.reason = 1;
      } else {
        this.valid.reason = 0;
      }
      if (this.info.requestName != null && this.info.requestName.length >= 3) {
        this.valid.requestName = 1;
      } else {
        this.valid.requestName = 0;
      }
      if (this.info.requestCharge != null && this.info.requestCharge.length >= 6) {
        this.valid.requestCharge = 1;
      } else {
        this.valid.requestCharge = 0;
      }
      if (this.valid.name && this.valid.reason && this.valid.requestName && this.valid.requestCharge) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },
    sendForm () {
      if (this.isValid) {
        this.processingData();
      }
    },
    processingData () {
      this.isLoading = true;
      serviceMail().post('/unsubscribe/google', this.info).then(() => {
        this.info = {
          name: '',
          reason: '',
          requestName: '',
          requestCharge: ''
        };
        this.isLoading = false;
        alert('Solicitud enviada con éxito!');
      }).catch((err) => {
        this.isLoading = false;
        console.error(err);
      });
    },
    sendClose () {
      window.location.href = '/';
    }
  },
  watch: {
    'info.name': {
      handler () {
        this.checkValid();
      }
    },
    'info.reason': {
      handler () {
        this.checkValid();
      }
    },
    'info.requestName': {
      handler () {
        this.checkValid();
      }
    },
    'info.requestCharge': {
      handler () {
        this.checkValid();
      }
    }
  }
};
</script>
<style lang="scss">
html, body, #app {
  height: 100%;
}
.parentGoogleSub {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.unsubscribe-google {
    border-radius: 20px;
    border: 1px solid #A1A5AB;
    background: #FFF;
    padding: 20px;
    width: 727px;
    height: 627px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
        width: 100%;
        height: calc(100% - 40px);
        overflow: auto;
        padding: 14px;
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
    .left {
        width: 50%;
        @media screen and (max-width: 768px) {
            width: 100%;
            order: 2;
        }
        &-alert {
            position: absolute;
            top: 50%;
            z-index: 1;
        }
    }
    .rigth {
        width: 50%;
        @media screen and (max-width: 768px) {
            width: 100%;
            order: 1;
            margin-bottom: 20px;
        }
    }
    .borderError {
        border-color: red !important;
        &::placeholder {
            color: red !important;
            font-size: 10px !important;
        }
    }
    .form-date {
        text-transform: capitalize;
        margin-top: 20px;
        margin-bottom: 12px;
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .unsubscribe-head {
        display: flex;
        flex-direction: column;
        &-title {
            width: 249px;
            color: #444B57;
            font-family: 'Source Sans Pro';
            font-size: 21px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 8px;
        }
        &-subtitle {
            width: 336px;
            color: #444B57;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }
    .unsubscribe-form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        &-item {
          width: 310px;
          margin-bottom: 12px;
          label {
              color: #444B57;
              font-family: 'Source Sans Pro';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 6px;
          }
          input {
              border-radius: 4px;
              border: 1px solid #444B57;
              background: #FFF;
              outline: none;
              &::placeholder {
                  color: #444B57;
                  font-family: 'Source Sans Pro';
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
              }
          }
          @media screen and (max-width: 768px) {
            width: 354px;
          }
        }
    }
    .unsubscribe-footer {
        margin-bottom: 30px;
        width: 310px;
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media screen and (max-width: 768px) {
          width: 354px;
        }
    }
    .unsubscribe-actions {
        gap: 10px;
        width: 310px;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            width: 150px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 10px;
            cursor: pointer;
            outline: none;
        }
        &-send {
            background: #444B57;
            text-align: center;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .disabled {
            opacity: 0.6;
            color: #FFF;
            pointer-events: none;
            cursor: no-drop;
        }
        .enabled {
            color: #FFF;
            font-weight: 600;
        }
        &-close {
            border: 1px solid #444B57;
            background: #FFF;
            color: #444B57;
            text-align: center;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        @media screen and (max-width: 768px) {
          width: 354px;
        }
    }
    .unsubscribe-image {
        width: 100%;
        height: 587px;
        flex-shrink: 0;
        border-radius: 10px;
        background-image: url('/assets/images/computador.png');
        background-color: lightgray;
        background-position: -5px -2px;
        background-size: 103% 101%;
        background-repeat: no-repeat;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        @media screen and (max-width: 768px) {
          height: 333.912px;
          background-position: -4px -1px;
          background-size: 102% 102%;
          background-image: url('/assets/images/mobile.png');
        }
    }
}
</style>
